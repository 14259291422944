import React, { FormEvent, useRef } from "react"
import "./ContactContainer.css"
import Footer from "../../components/Footer/Footer"
import emailjs from "@emailjs/browser"

const PUBLIC_KEY: string =
  process.env.REACT_APP_PUBLIC_KEY ||
  (process.env.REACT_APP_VERCEL_PUBLIC_KEY as string)
const TEMPLATE_ID: string = (process.env.REACT_APP_TEMPLATE_ID ||
  process.env.REACT_APP_VERCEL_TEMPLATE_ID) as string
const SERVICE_ID: string = (process.env.REACT_APP_SERVICE_ID ||
  process.env.REACT_APP_VERCEL_SERVICE_ID) as string

console.log(PUBLIC_KEY, TEMPLATE_ID, SERVICE_ID)
console.log(process.env)
const ContactContainer = () => {
  const ref = useRef<HTMLFormElement>(null)
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    ref.current &&
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, ref.current, PUBLIC_KEY).then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )
    e.currentTarget.reset()
  }

  return (
    <div className="container custom-container">
      <div className="content-wrapper contact">
        <h1 className="page-title">Contact Me</h1>
        <form ref={ref} onSubmit={handleSubmit}>
          <div className="form">
            <label htmlFor="name">Name</label>
            <input
              className="input-text"
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
            ></input>
            <label htmlFor="email">Email</label>
            <input
              className="input-text"
              type="email"
              id="email"
              name="email"
              placeholder="Your email.."
            ></input>
            <label htmlFor="subject">Subject</label>
            <select id="subject" name="subject">
              <option value="general">General</option>
              <option value="commissions">Commissions</option>
              <option value="other">Other</option>
            </select>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Write something.."
            ></textarea>
            <input className="submit" type="submit" value="Submit"></input>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ContactContainer

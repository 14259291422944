import React, { useEffect } from "react"

import "./HomeContainer.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons"
import HomeBackground from "../../components/HomeBackground/HomeBackground"

const HomeContainer = () => {
  const [videoPlaying, setVideoPlaying] = React.useState(false)
  const [classString, setClassString] = React.useState("videoBackground hidden")

  useEffect(() => {
    if (videoPlaying) {
      setClassString("videoBackground visible")
    } else {
      setClassString("videoBackground hidden")
    }
  }, [videoPlaying])
  return (
    <div className={"homeContainer container imageBackground"}>
      <HomeBackground className="homeBackground" />
    </div>
  )
}

export default HomeContainer

import React, { useEffect, useRef, useState } from "react"
import "./NavBar.css"
import logo from "../../assets/svg/logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons"

const DesktopNavBar = () => {
  return (
    <div className="navbar">
      <a href="/">
        <img className="logo" src={logo} alt="Angharads Logo"></img>
      </a>
      <a className="link" href="/">
        home
      </a>
      <a className="link" href="/hello">
        hello
      </a>
      <a className="link" href="/galleries">
        galleries
      </a>
      <a className="link" href="/projects">
        projects
      </a>
      <a className="link" href="/shop">
        shop
      </a>
      <a className="link" href="/contact">
        contact
      </a>
    </div>
  )
}
interface MobileNavBarProps {
  menuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const MobileNavBar = ({ menuOpen, setMenuOpen }: MobileNavBarProps) => {
  const [mobileNavbarStyle, setMobileNavbarStyle] = useState(
    menuOpen ? "mobile-navbar open" : "mobile-navbar"
  )
  const handleOnClick = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    setMobileNavbarStyle(menuOpen ? "mobile-navbar open" : "mobile-navbar")
  }, [menuOpen])
  return (
    <div className={mobileNavbarStyle}>
      <a href="/">
        <img className="logo" src={logo} alt="Angharads Logo"></img>
      </a>

      {menuOpen && (
        <div className="vertical-links">
          <a className="vertical-link" href="/">
            home
          </a>
          <a className="vertical-link" href="/hello">
            hello
          </a>
          <a className="vertical-link" href="/galleries">
            galleries
          </a>
          <a className="vertical-link" href="/projects">
            projects
          </a>
          <a className="vertical-link" href="/shop">
            shop
          </a>
          <a className="vertical-link" href="/contact">
            contact
          </a>
        </div>
      )}
      <div
        className={`menu-button-wrapper ${
          menuOpen && "menu-button-wrapper--open"
        }`}
        onClick={handleOnClick}
      >
        {!menuOpen && <label>Open Menu</label>}
        {!menuOpen && <div className="horizontal-line" />}
        <FontAwesomeIcon
          className="menu-button"
          icon={menuOpen ? faChevronUp : faChevronDown}
        />
        {menuOpen && <div className="horizontal-line" />}
        {menuOpen && <label>Close Menu</label>}
      </div>
      <div className={menuOpen ? "backgroundBlur" : ""} />
    </div>
  )
}

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  console.log(innerWidth, innerHeight)
  return {
    innerWidth,
    innerHeight,
  }
}
const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [navbarWrapperStyle, setNavbarWrapperStyle] = useState(
    windowSize.innerWidth < 1000
      ? "navbarWrapper mobile"
      : "navbarWrapper desktop"
  )

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (windowSize.innerWidth < 1000) {
      setNavbarWrapperStyle("navbarWrapper mobile")
    } else {
      setNavbarWrapperStyle("navbarWrapper desktop")
    }
  }, [windowSize])

  return (
    <div
      className={`${navbarWrapperStyle} ${
        window.location.pathname === "/" ? "nobackground" : "background"
      } ${menuOpen ? "open" : ""}`}
    >
      {windowSize.innerWidth < 1000 ? (
        <MobileNavBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      ) : (
        <DesktopNavBar />
      )}
    </div>
  )
}
export default NavBar

import { useEffect, useState } from "react"
import useProjectScript from "../../hooks/useProjectScript"
import "./ProjectsContainer.css"

const ProjectsContainer = () => {
  const [element, setElement] = useState(
    document.getElementById("projects-container")
  )

  useEffect(() => {
    setElement(document.getElementById("projects-container"))
  }, [])
  useProjectScript(
    "https://gallery.angharadbache.com/-fromazillennial/slideswebcomponentembed.js/6443c1acd7c17336106e6f0b?features=lightbox&filtertags=",
    element
  )
  return (
    <div className="container projects-container" id="projects-container"></div>
  )
}

export default ProjectsContainer

import React from "react"
import "./HelloContainer.css"

import lowResProfile from "../../assets/video/ang-about-compressed.gif"
import highResProfile from "../../assets/video/ang-about.gif"
import ProgressiveImage from "../../components/ProgressiveImage/ProgressiveImage"
import Footer from "../../components/Footer/Footer"

const HelloContainer = () => {
  return (
    <div className="container custom-container">
      <div className="content-wrapper">
        <h1 className="page-title">Hello</h1>
        <div className="text-image-wrapper">
          <div className="text-wrapper">
            <div className="flex-grow">
              <div>
                Angharad Bache (b. 1996, Winchester) is an emerging social
                documentary and landscape photographer from the West Midlands.
                She graduated from Aberystwyth University in 2017 with a BA in
                English Literature and Creative Writing, and is still based in
                West Wales.
              </div>

              <div>
                In 2020, her photograph ‘Just One More Night Shift’ was
                selected, by a panel including the Princess of Wales, to feature
                in the National Portrait Gallery’s Hold Still project. Since
                then, she has tutored for the North Wales region of the Royal
                Photographic Society, and has had work featured in several news
                outlets.
              </div>

              <div>
                Bache’s latest work ‘from a zillennial’ contributed to her MA in
                Photography at Falmouth University. The project is an
                exploration of the experiences and struggles faced by the
                Millennial and Gen Z generations in Britain today.
              </div>
            </div>
          </div>
          {/* <img
          className="profile-image"
          src={profile}
          alt="Profile of Angharad"
        /> */}
          <ProgressiveImage
            lowResSrc={lowResProfile}
            highResSrc={highResProfile}
            alt="Profile of Angharad"
            className="profile-image"
          />
        </div>
      </div>
    </div>
  )
}

export default HelloContainer

import React from "react"
import "./HomeBackground.css"
// @ts-ignore
import video from "../../assets/video/ang-website-video.mp4"

interface HomeBackgroundProps {
  className: string
}
const HomeBackground = ({ className }: HomeBackgroundProps) => {
  return (
    <div className={`${className} wrapper`}>
      <video
        src={video}
        autoPlay
        loop
        muted
        playsInline
        className={`image show`}
      />
    </div>
  )
}

export default HomeBackground

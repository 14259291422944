import React from "react"
import "./ProgressiveImage.css"

interface ProgressiveImageProps {
  lowResSrc: string
  highResSrc: string
  alt: string
  className: string
}
const ProgressiveImage = ({
  lowResSrc,
  highResSrc,
  alt,
  className,
}: ProgressiveImageProps) => {
  return (
    <div className={`${className} wrapper`}>
      <img
        src={"https://i.imgur.com/sPxGWFf.png"}
        alt={alt}
        className={"image"}
      />
      <img
        src={"https://i.imgur.com/OnlncKv.gif"}
        alt={alt}
        className={"image"}
      />
    </div>
  )
}

export default ProgressiveImage

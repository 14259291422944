import "./ShopContainer.css"

import React, { useState } from "react"
import "./ShopContainer.css"
import useShopScript from "../../hooks/useShopScript"

const ShopContainer = () => {
  return (
    <div className="container galleryContainer">
      <div className="iframe-container">
        <script src="https://pictimecloudaf-m.azureedge.net/pictures/scripts/compiled/artgalleryembed.js"></script>
        <iframe
          title="Pictime Integration"
          id="pictimeIntegration"
          src="https://gallery.angharadbache.com/art?headless=true"
          className="pictime-iframe"
        ></iframe>
      </div>
    </div>
  )
}

export default ShopContainer

import React from "react"
import "./App.css"
import NavBar from "./components/NavBar/NavBar"
import HomeContainer from "./containers/HomeContainer/HomeContainer"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import HelloContainer from "./containers/HelloContainer/HelloContainer"
import GalleriesContainer from "./containers/GalleriesContainer/GalleriesContainer"
import ProjectsContainer from "./containers/ProjectsContainer/ProjectsContainer"
import ShopContainer from "./containers/ShopContainer/ShopContainer"
import ContactContainer from "./containers/ContactContainer/ContactContainer"
import Footer from "./components/Footer/Footer"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeContainer />,
  },
  {
    path: "/hello",
    element: <HelloContainer />,
  },
  {
    path: "/galleries",
    element: <GalleriesContainer />,
  },
  { path: "/projects", element: <ProjectsContainer /> },
  { path: "/shop", element: <ShopContainer /> },
  { path: "/contact", element: <ContactContainer /> },
])
function App() {
  return (
    <div className="app">
      <NavBar />
      <RouterProvider router={router} />
      <Footer />
    </div>
  )
}

export default App

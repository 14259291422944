import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import afterEffectsBadge from "../../assets/images/after-effects.png"
import premierProBadge from "../../assets/images/premier-pro.png"
import proVideoDesignBadge from "../../assets/images/pro-video-design.png"
import profile from "../../assets/images/ProfileImage.jpg"
import rps from "../../assets/images/rps-wip.png"
import React, { useEffect } from "react"

const AdobeBadge = ({ badge, link }: { badge: string; link: string }) => {
  const [element, setElement] = React.useState(
    document.getElementById("credly-badge")
  )
  React.useEffect(() => {
    setElement(document.getElementById("credly-badge"))
  }, [])

  React.useEffect(() => {
    if (element) {
      const script = document.createElement("script")
      script.src = "//cdn.credly.com/assets/utilities/embed.js"
      script.async = true
      element.appendChild(script)
    }
  }, [element])

  return (
    <a href={link}>
      <img
        className="badge-image"
        src={badge}
        alt="Angharad's After Effects Qualification"
      ></img>
    </a>
  )
}

const checkShowFooter = () => {
  if (window.location.pathname === "/") {
    return false
  } else {
    return true
  }
}

const Footer = () => {
  const [showFooter, setShowFooter] = React.useState(checkShowFooter())

  useEffect(() => {
    setShowFooter(checkShowFooter())
  }, [showFooter])

  return showFooter ? (
    <div className="footer">
      <div className="socials">
        <FontAwesomeIcon
          size={"2xl"}
          className="fa-xl videoIconStyle"
          icon={faFacebook}
          onClick={() => {
            window.open("https://www.facebook.com/AngharadBachePhotography")
          }}
        />
        <FontAwesomeIcon
          size={"2xl"}
          className="fa-xl videoIconStyle"
          icon={faInstagram}
          onClick={() => {
            window.open(
              "https://instagram.com/angharadbache?igshid=YmMyMTA2M2Y="
            )
          }}
        />
      </div>
      <img
        src={rps}
        className="rps-logo"
        alt="Royal Photographic Society Women In Photography Group Logo"
      ></img>
      <div className="adobe">
        <AdobeBadge
          badge={premierProBadge}
          link={
            "https://www.credly.com/badges/7d1f56fd-fee9-428f-85c0-82b8dfac5123/public_url"
          }
        />
        <AdobeBadge
          badge={afterEffectsBadge}
          link="https://www.credly.com/badges/80c15719-b0c5-4bfa-9139-1054bb35c9a6/public_url"
        />
        <AdobeBadge
          badge={proVideoDesignBadge}
          link="https://www.credly.com/badges/8500c59a-90f9-45bd-8fc9-25f86df640fd/public_url"
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Footer

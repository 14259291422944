import { useEffect } from "react"

const useProjectScript = (url: string, element: HTMLElement | null) => {
  useEffect(() => {
    const template = document.createElement("template")
    const script = document.createElement("script")
    // @ts-ignore
    template["data-pt-type"] = "slideshow"
    // @ts-ignore
    template["data-pt-scriptslideshowid"] = "6443c1acd7c17336106e6f0b"

    script.src = url
    script.async = true
    script.type = "text/javascript"
    // @ts-ignore
    script["data-pt-scriptslideshowid"] = "6443c1acd7c17336106e6f0b"

    if (element) {
      element.appendChild(template)
      element.appendChild(script)

      return () => {
        element.removeChild(template)
        element.removeChild(script)
      }
    }
  }, [element])
}

export default useProjectScript
